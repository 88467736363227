import React from "react";
import "./style.css";
import HeroSlider from "./HeroSlider";
// utills
import SpaceBox from "../../Utills/SpaceBox";
import HomeContent from "./HomeContent";

const Home = () => {
  return (
    <>
      <div className="main_wrapper home">
        <SpaceBox />
        <div className="c_container">
          <HeroSlider />
          <HomeContent />
        </div>
      </div>
    </>
  );
};

export default Home;
