import React from "react";
// assets
import aboutusImg1 from "../../assets/images/About-Us-1.jpg";
import aboutusImg2 from "../../assets/images/About-Us-2.jpg";
import aboutusImg3 from "../../assets/images/About-Us-3.jpg";
import aboutusImg4 from "../../assets/images/About-Us-4.jpg";
import aboutusImg5 from "../../assets/images/logo.svg";
// splide slider
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

const HeroSlider = () => {
  return (
    <>
      <div className="hero_img">
        <Splide
          options={{
            type: "fade",
            arrows: false,
            pagination: true,
            perMove: 1,
            perPage: 1,
            autoplay: true,
            delay: "100",
            width: "100%",
            interval: "2200",
            rewind: true,
            pauseOnFocus: false,
            pauseOnHover: false,
          }}
        >
          <SplideSlide>
            <img src={aboutusImg1} alt="" />
          </SplideSlide>
          <SplideSlide>
            <img src={aboutusImg2} alt="" />
          </SplideSlide>
          <SplideSlide>
            <img src={aboutusImg3} alt="" />
          </SplideSlide>
          <SplideSlide>
            <img src={aboutusImg4} alt="" />
          </SplideSlide>
          <SplideSlide>
            <img src={aboutusImg5} alt="" className="_logo" />
          </SplideSlide>
        </Splide>
      </div>
    </>
  );
};

export default HeroSlider;
