import "./App.css";
import { Routes, Route } from "react-router-dom";
// components
import Home from "./pages/Home";
import Logistics from "./pages/Logistics";
import Header from "./layout/Header";
import InclousiveGrowth from "./pages/InclusiveGrowth";
import SiteDetails from "./pages/SiteDetails";
import Contact from "./pages/Contact";
import Footer from "./layout/Footer";


function App() {
  console.warn = () => {};
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/logistics" element={<Logistics />}></Route>
        <Route path="/inclusive-growth" element={<InclousiveGrowth />}></Route>
        <Route path="/site-details" element={<SiteDetails />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
