import React from "react";
import "./style.css";
import LogisticContainer from "./LogisticContainer";
// utills
import SpaceBox from "../../Utills/SpaceBox";
import Divider from "../../components/Divider/Divider";
import LogisticContent from "./LogisticContent";

const Logistics = () => {
  return (
    <>
      <div className="main_wrapper logistic_section">
        <SpaceBox />
        <div className="c_container">
          <LogisticContainer />
          <Divider />
          <LogisticContent />
        </div>
      </div>
    </>
  );
};

export default Logistics;
