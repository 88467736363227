import React from "react";
// component
import Heading from "../../components/Heading/Heading";
// asset
import shutterstock from "../../assets/images/shutterstock.jpg";

const InclusiveContent = () => {
  return (
    <>
      <div className="inclusive_content">
        <div>
          <Heading text="Inclusive Growth" />
          <p>
            Through this initiative we emphasize our commitment to sustainable
            development as we seek and create growth through transparent
            business practices and value creation for all our stakeholders. We
            foster collaboration and equitable opportunities to advance
            community based economic growth and environmental sustainability.
          </p>
        </div>
        <img src={shutterstock} alt="" />
      </div>
    </>
  );
};

export default InclusiveContent;
