import React from "react";
// component
import Heading from "../../components/Heading/Heading";
// assets
import aboutusImg1 from "../../assets/images/About-Us-2.jpg";
import aboutusImg2 from "../../assets/images/Logistics-Services-6.jpg";
import aboutusImg3 from "../../assets/images/Logistics-Services-5.jpg";
import aboutusImg4 from "../../assets/images/Logistics-Services-7.jpg";
import aboutusImg5 from "../../assets/images/Logistics-Services-8.jpg";
import aboutusImg6 from "../../assets/images/Logistics-Services-1.jpg";
const LogisticContent = () => {
  return (
    <>
      <div className="logistic_container">
        <div className="lc_l">
          <Heading text="Logistics Services" />
          <p>
            We facilitate the import and export of bulk commodities through
            effective supply chain management solutions. Our expertise lies in
            creating dynamic solutions for our client’s unique challenges. Our
            facilities have the capacity to handle a turnover in excess of
            10,000 tons per day for imports and more than 5,000 tons per day for
            exports.
          </p>
          <h2>PRODUCTS HANDLED </h2>
          <div className="lc_wrap">
            <div className="lc_l2">
              <h6>Imports: </h6>
              <ul>
                <li>Coal</li>
                <li>Pet Coke</li>
                <li>Urea / Fertilizer</li>
                <li>Soybean </li>
                <li>Steel</li>
                <li>Lumber</li>
              </ul>
            </div>
            <div className="lc_l2">
              <h6>Exports: </h6>
              <ul>
                <li>Cement Clinker</li>
                <li>Bulk Cement</li>
                <li>Cement Bags</li>
                <li>Limestone </li>
                <li>Iron Ore </li>
                <li>Manganese Ore </li>
                <li>Rice Jumbo Bags</li>
                <li>Tapioca Chips</li>
                <li>Corn Kernels</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="lc_r">
          <img src={aboutusImg2} alt="" />
          <img src={aboutusImg1} alt="" />
          <img src={aboutusImg3} alt="" />
          <img src={aboutusImg4} alt="" />
          <img src={aboutusImg5} alt="" />
          <img src={aboutusImg6} alt="" />
        </div>
      </div>
    </>
  );
};

export default LogisticContent;
