import React, { useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
// assets
import logo from "../../assets/images/logo.svg";
import menu from "../../assets/icons/menu.png";

const Header = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <>
      <div className="header">
        <div
          className="_overlay"
          onClick={() => setOpenSidebar(false)}
          style={{ display: !openSidebar ? "none" : "block" }}
        ></div>
        <div className="h_container">
          <div className="logo_brand">
            <NavLink to="/">
              <img src={logo} alt="..." />
            </NavLink>
          </div>
          <button
            className="nav_btn"
            onClick={() => setOpenSidebar(!openSidebar)}
          >
            <img src={menu} alt="" />
          </button>
          <div
            className="nav_items"
            style={{ left: !openSidebar ? "-1000px" : "0" }}
          >
            <NavLink
              onClick={() => setOpenSidebar(false)}
              className="mb_logo"
              to="/"
            >
              <img src={logo} alt="..." />
            </NavLink>
            <NavLink
              onClick={() => setOpenSidebar(false)}
              className={({ isActive }) => (isActive ? "_active" : "")}
              to="/"
            >
              About us
            </NavLink>
            <NavLink
              onClick={() => setOpenSidebar(false)}
              className={({ isActive }) => (isActive ? "_active" : "")}
              to="logistics"
            >
              LOGISTICS SERVICES
            </NavLink>
            <NavLink
              onClick={() => setOpenSidebar(false)}
              className={({ isActive }) => (isActive ? "_active" : "")}
              to="site-details"
            >
              Site Details
            </NavLink>
            <NavLink
              onClick={() => setOpenSidebar(false)}
              className={({ isActive }) => (isActive ? "_active" : "")}
              to="inclusive-growth"
            >
              INCLUSIVE GROWTH
            </NavLink>
            <NavLink
              onClick={() => setOpenSidebar(false)}
              className={({ isActive }) => (isActive ? "_active" : "")}
              to="contact"
            >
              Contact Us
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
