import React from "react";
// component
import Heading from "../../components/Heading/Heading";
// magnifier
import Magnifier from "react-magnifier";
import "./style.css";
// utills
import SpaceBox from "../../Utills/SpaceBox";
// assets
import layer1 from "../../assets/images/Layer 8.png";
import layer2 from "../../assets/images/Layer 9.png";
import tree from "../../assets/icons/tree.png";
import dust from "../../assets/icons/dust.png";
import security from "../../assets/icons/security.png";
import internal from "../../assets/icons/internal.png";
import concreted from "../../assets/icons/concreted.png";
import laterite from "../../assets/icons/laterite.png";
import drainage from "../../assets/icons/drainage.png";
import sedimentation from "../../assets/icons/sedimentation.png";
import portImport from "../../assets/icons/port-import.png";
import portExport from "../../assets/icons/port-export.png";

const SiteDetails = () => {
  return (
    <>
      <div className="main_wrapper sitedetails_section">
        <SpaceBox />
        <div className="c_container">
          <Heading text="Site Details" />

          <div className="sd_content">
            <div className="sd_l">
              <div className="sdl_i">
                <div>1</div>
                <h5>office</h5>
              </div>
              <div className="sdl_i">
                <div>2</div>
                <h5>WEIGH SCALE</h5>
              </div>

              <div className="sdl_i">
                <div>3</div>
                <h5>WHEEL WASHING STATION</h5>
              </div>

              <div className="sdl_i">
                <div>4</div>
                <h5>ENTRANCE/EXIT</h5>
              </div>

              <div className="sdl_i">
                <div>5</div>
                <h5>WATER TRANSFER STATION</h5>
              </div>

              <div className="sdl_i sdl_ii sdl_it">
                <img src={tree} alt="" />
                <h5>TREES</h5>
              </div>
              <div className="sdl_i sdl_ii">
                <img src={dust} alt="" />
                <h5>DUST SUPPRESSION SPRINKLERS</h5>
              </div>
              <div className="sdl_i sdl_ii">
                <img src={security} alt="" />
                <h5>SECURITY STATION</h5>
              </div>
              <div className="sdl_i sdl_ii">
                <img src={internal} alt="" />
                <h5>INTERNAL ROADS</h5>
              </div>
              <div className="sdl_i sdl_ii">
                <img src={concreted} alt="" />
                <h5>CONCRETED STOCKYARD</h5>
              </div>
              <div className="sdl_i sdl_ii">
                <img src={laterite} alt="" />
                <h5>LATERITE STOCKYARD</h5>
              </div>

              <div className="sdl_i sdl_ii">
                <img src={drainage} alt="" />
                <h5>DRAINAGE</h5>
              </div>

              <div className="sdl_i sdl_ii">
                <img src={sedimentation} alt="" />
                <h5>SEDIMENTATION PIT</h5>
              </div>

              <div className="sdl_i sdl_ii">
                <img src={portImport} alt="" />
                <h5>PORT (IMPORT)</h5>
              </div>

              <div className="sdl_i sdl_ii">
                <img src={portExport} alt="" />
                <h5>PORT (EXPORT)</h5>
              </div>
              <div className="sdl_iii">
                <Magnifier mgWidth={250} mgHeight={250} src={layer2} />
              </div>
            </div>
            <div className="sd_r">
              <Magnifier mgWidth={250} mgHeight={250} src={layer1} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteDetails;
