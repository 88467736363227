import React from "react";
// component
import Heading from "../../components/Heading/Heading";
// assets
import aboutusImg2 from "../../assets/images/About-Us-2.jpg";
const HomeContent = () => {
  return (
    <>
      <div className="hero_content hc1">
        <div className="h_para">
          <Heading text="About Us" />
          <p>
            Lucky Resources & Logistics offers dynamic supply chain management
            for the import and export of bulk commodities in Thailand.
            Incorporating the latest technology and environmentally friendly
            practices, our marine, port and inland logistics solutions are
            managed from our river port and stockyard in Ayutthaya as well as
            our offices in Bangkok. Covering all areas of Thailand and reaching
            out into the South-East Asian region and beyond, our logistical
            network covers every step of the supply chain, from supplier to
            end-user, to offer our clients the most comprehensive and
            cost-effective solutions for all their bulk commodity logistical
            needs.
          </p>
        </div>
        <div className="h_img">
          <img src={aboutusImg2} alt="" />
        </div>
      </div>
    </>
  );
};

export default HomeContent;
