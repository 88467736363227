import React from "react";
import "./style.css";
import InclusiveContent from "./InclusiveContent";
import InclusiveGallery from "./InclusiveGallery";
// utills
import SpaceBox from "../../Utills/SpaceBox";
import Divider from "../../components/Divider/Divider";

const InclousiveGrowth = () => {
  return (
    <>
      <div className="main_wrapper logistic_section">
        <SpaceBox />
        <div className="c_container">
          <InclusiveContent />
          <Divider />
          <InclusiveGallery />
        </div>
      </div>
    </>
  );
};

export default InclousiveGrowth;
