import React from "react";
// assets
import logisticsIcon3 from "../../assets/images/Layer 3.png";
import logisticsIcon4 from "../../assets/images/Layer 4.png";
import logisticsIcon5 from "../../assets/images/Layer 5.png";
import logisticsIcon6 from "../../assets/images/Layer 6.png";
import logisticsIcon7 from "../../assets/images/Layer 7.png";

const LogisticContent = () => {
  return (
    <>
      <div className="logistic_content">
        <div className="lc_content_l">
          <div>
            <img src={logisticsIcon3} alt="..." />
          </div>
        </div>
        <div className="_lc_content_r">
          <div>
            <h2>MARINE</h2>
            <p>Our marine logistics services include: </p>
            <ul>
              <li>MV Brokerage Services</li>
              <li>Customs Clearance</li>
              <li>Stevedoring</li>
              <li>Barge Transportation</li>
            </ul>
            <p>
              In addition to our facilities in Ayutthaya, we also facilitate
              import and export through our strategic network of partner ports
              across Thailand.
            </p>
          </div>
        </div>
      </div>
      <div className="logistic_content">
        <div className="lc_content_l">
          <div>
            <img src={logisticsIcon4} alt="..." />
          </div>
        </div>
        <div className="_lc_content_r">
          <div>
            <h2>PORT</h2>
            <p>Our port services include: </p>
            <h3>FOR IMPORTS</h3>
            <ul>
              <li>Docking at Port</li>
              <li>Unloading</li>
              <li>Internal Trucking</li>
              <li>Weighing</li>
              <li>Stocking</li>
              <li>Inland Logistics for Delivery</li>
            </ul>
            <h3>FOR EXPORTS</h3>
            <ul>
              <li>Inland Logistics for Delivery</li>
              <li>Stocking</li>
              <li>Internal Trucking</li>
              <li>Weighing</li>
              <li>Docking at Port</li>
              <li>Barge Loading</li>
            </ul>
            <p>
              Our port services ensure convenience, professionalism and
              environmental sustainability at every step of the logistics
              process.
            </p>
          </div>
        </div>
      </div>
      <div className="logistic_content">
        <div className="lc_content_l">
          <div>
            <img src={logisticsIcon5} alt="..." />
          </div>
        </div>
        <div className="_lc_content_r">
          <div>
            <h2>STOCKYARD</h2>
            <p>Our stockyard services include:</p>
            <ul>
              <li>Stockyard Rental</li>
              <li>Stockpiling</li>
              <li>Internal Trucking</li>
              <li>Weighing</li>
              <li>Stock Covering</li>
              <li>Loading onto Truck</li>
              <li>Inland Logistics for Delivery</li>
            </ul>
            <p>
              Our stockyard offers optimized stocking areas managed under strict
              environmental protocols. We provide concreted yard and laterite
              yard stocking options along with CCTV monitoring systems and real
              time inventory management.
            </p>
          </div>
        </div>
      </div>
      <div className="logistic_content">
        <div className="lc_content_l">
          <div>
            <img src={logisticsIcon6} alt="..." />
          </div>
        </div>
        <div className="_lc_content_r">
          <div>
            <h2>THIRD-PARTY LOGISTICS CONSULTANCY </h2>
            <p>
              We also offer effective cost-saving solution for our third-party
              logistics consultancy services.
            </p>
            <p>
              Our industry experience and expertise ensure clients cost
              effectiveness, time efficiency and eases bureaucratic obstacles.
              Our professional consultation services can be optimized to each
              client’s needs across the entire supply chain process or on
              individual stages.
            </p>
          </div>
        </div>
      </div>
      <div className="logistic_content">
        <div className="lc_content_l">
          <div>
            <img src={logisticsIcon7} alt="..." />
          </div>
        </div>
        <div className="_lc_content_r">
          <div>
            <h2>INLAND CONTAINER DEPOT DEVELOPMENT PLANS</h2>
            <p>
              We are engaged in research and development plans and are exploring
              potential partnerships for the development of an Inland Container
              Depot to connect to Laem Chabang and Bangkok ports. Our location
              is located at the center of major industrial estates in Ayutthaya
              and is also the gateway to the North and Northeastern regions in
              Thailand. This will contribute to the government’s policy of
              increasing multi-modal logistics to contribute to optimized
              logistics costs within the country.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LogisticContent;
