import React from "react";

const LocationDetails = ({
  officeName,
  location,
  mapLocationSrc,
  contactDetails,
}) => {
  return (
    <>
      <div className="cr_2i">
        <div>
          <h2>{officeName}</h2>
          <p>{location}</p>
          {contactDetails}
        </div>
        <iframe
          src={mapLocationSrc}
          width="100%"
          height="200"
          style={{ border: "0" }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default LocationDetails;
