import React from "react";
import "./style.css";
import Form from "./Form";

// utills
import SpaceBox from "../../Utills/SpaceBox";
import Heading from "../../components/Heading/Heading";
import LocationDetails from "./LocationDetails";

const Contact = () => {
  return (
    <>
      <div className="contact">
        <SpaceBox />
        <div className="c_container">
          <div className="con_right">
            <div>
              <div>
                <Heading text="Contact Us" />
                <Form />
              </div>
              <div className="cr_2">
                <LocationDetails
                  officeName="Head Office"
                  location="75/39 Ocean Tower 2, 21st Floor, Soi Sukhumvit 19 (Wattana),
                Sukhumvit Road, Klongtoey-Nua, Wattana, Bangkok Thailand 10110"
                  contactDetails={
                    <>
                      {" "}
                      <h5>
                        <strong>Tel:</strong> +66 (0)2 260 8181
                      </h5>
                    </>
                  }
                  mapLocationSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.597185581192!2d100.5614649!3d13.742820499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29ee517764ff1%3A0xad71d2a46c0aadf9!2sOcean%20Tower%202!5e0!3m2!1sen!2s!4v1660726732767!5m2!1sen!2s"
                />
                <LocationDetails
                  officeName="Ayutthaya Office"
                  location="202 Moo 2 Tambon Khlong Sakae, Amphur Nakhon Luang,
                  Ayutthaya 13260, Thailand"
                  mapLocationSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3864.186023372393!2d100.59406249999999!3d14.4164375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2751ec5ac4e67%3A0x3125420c7c82afa4!2z4LiX4LmI4Liy4Lil4Lix4LiE4LiB4Li14LmJ!5e0!3m2!1sen!2s!4v1660900056362!5m2!1sen!2s"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
