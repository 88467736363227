import React, { useState } from "react";
// email js
import emailjs from "@emailjs/browser";
const Form = () => {
  const [username, setUsername] = useState("");
  const [emailVal, setEmailVal] = useState("");
  const [message, setMessage] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();

    emailjs
      .send(
        // process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        // process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        "service_oelxnnv",
        "template_7amzqyh",
        {
          to: `info@luckyrl.com`,
          to_name: `AW LUCKY`,
          from_email_name: username,
          subject: "",
          message: `hello this is user ${username}, user email ${emailVal} and the message is ${message}`,
          from: "info@luckyrl.com",
          reply_to: "info@luckyrl.com",
        },
        "hwM7KiVlnHE9hoVni"
      )
      .then(() => {
        // console.log("success...");
        setUsername("");
        setEmailVal("");
        setMessage("");
      })
      .catch((err) => {
        console.log("admin Email sending FAILED...", err);
      });
  };
  return (
    <>
      <form onSubmit={sendEmail} className="contact_form">
        <input
          type="text"
          required
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          placeholder="Name"
        />
        <input
          required
          onChange={(e) => {
            setEmailVal(e.target.value);
          }}
          value={emailVal}
          type="email"
          placeholder="Email"
        />
        <textarea
          required
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder="Message"
          cols="30"
          rows="10"
        ></textarea>
        <button type="submit">Submit</button>
      </form>
    </>
  );
};

export default Form;
