import React from "react";
// assets
import aboutusImg1 from "../../assets/images/School Scholarships.jpg";
import aboutusImg2 from "../../assets/images/Act. with the elderly_1.jpg";
import aboutusImg3 from "../../assets/images/Clean the road_1.jpg";
import aboutusImg4 from "../../assets/images/Clean the school_2.jpg";
import aboutusImg5 from "../../assets/images/Offering candles_2.jpg";
import aboutusImg6 from "../../assets/images/Plant a tree_1.jpg";

const InclusiveGallery = () => {
  return (
    <>
      <div className="inclusive_gallery">
        <div>
          <img src={aboutusImg2} alt="" />
        </div>
        <div>
          <img src={aboutusImg1} alt="" />
        </div>
        <div>
          <img src={aboutusImg3} alt="" />
        </div>
        <div>
          <img src={aboutusImg4} alt="" />
        </div>
        <div>
          <img src={aboutusImg5} alt="" />
        </div>
        <div>
          <img src={aboutusImg6} alt="" />
        </div>
      </div>
    </>
  );
};

export default InclusiveGallery;
